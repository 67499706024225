import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import NavBar from '../../components/navBar/NavBar';
import Footer from '../../components/footer/Footer';
import { Typography, Collapse, Button, Box } from '@mui/material';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot, TimelineOppositeContent, } from '@mui/lab';
import { Paper } from '@mui/material';
import { useApp } from '../../hooks/AppContext';
import cvEs from './cv.es.json';
import cvEn from './cv.en.json';
import cvFr from './cv.fr.json';
import WorkIcon from '@mui/icons-material/Work';
import SchoolIcon from '@mui/icons-material/School';
var About = function () {
    var _a = useApp(), appTranslate = _a.translate, currentLanguage = _a.currentLanguage;
    var _b = useState([]), cvData = _b[0], setCvData = _b[1];
    useEffect(function () {
        // Cargar el archivo correspondiente según el idioma actual
        switch (currentLanguage) {
            case 'en':
                setCvData(cvEn);
                break;
            case 'fr':
                setCvData(cvFr);
                break;
            case 'es':
            default:
                setCvData(cvEs);
                break;
        }
    }, [currentLanguage]);
    return (React.createElement(Container, { fluid: true },
        React.createElement(NavBar, null),
        React.createElement(Row, null,
            React.createElement(Col, { md: 2 }),
            React.createElement(Col, { md: 8 },
                React.createElement(Row, null,
                    React.createElement(Box, { sx: {
                            textAlign: 'center',
                            marginBottom: '2rem',
                            padding: '1rem',
                            backgroundColor: '#f5f5f5',
                            borderRadius: '8px',
                        } },
                        React.createElement(Typography, { variant: "h2", color: "primary", sx: { fontWeight: 'bold', marginBottom: '0.5rem' } }, appTranslate('moreAboutMe')),
                        React.createElement(Typography, { variant: "subtitle1", color: "textSecondary" }, appTranslate('moreAboutMe.intro')))),
                React.createElement(Row, null,
                    React.createElement(Cronologia, { cv: cvData, appTranslate: appTranslate }))),
            React.createElement(Col, { md: 2 })),
        React.createElement(Footer, null)));
};
var Cronologia = function (_a) {
    var cv = _a.cv, appTranslate = _a.appTranslate;
    return (React.createElement(Timeline, { position: "alternate", sx: { marginTop: '2rem' } }, cv.map(function (evento, index) {
        var _a = React.useState(false), open = _a[0], setOpen = _a[1];
        return (React.createElement(TimelineItem, { key: index },
            React.createElement(TimelineOppositeContent, null,
                React.createElement(Typography, { variant: "body1", sx: {
                        fontSize: '1rem',
                        fontWeight: 'bold',
                        color: '#3f51b5',
                        backgroundColor: '#e3f2fd',
                        padding: '4px 8px',
                        borderRadius: '8px',
                        textAlign: 'center',
                        display: 'inline-block',
                        marginBottom: '0.5rem',
                    } }, evento.date)),
            React.createElement(TimelineSeparator, null,
                React.createElement(TimelineDot, { sx: {
                        backgroundColor: evento.type === 'Experience' ? '#3f51b5' : '#4caf50',
                        color: '#fff',
                    } }, evento.type === 'Experience' ? React.createElement(WorkIcon, null) : React.createElement(SchoolIcon, null)),
                index < cv.length - 1 && React.createElement(TimelineConnector, null)),
            React.createElement(TimelineContent, null,
                React.createElement(Paper, { elevation: 3, sx: { padding: '16px', marginBottom: '1rem' } },
                    React.createElement(Typography, { variant: "h6", component: "h1" }, evento.title),
                    React.createElement(Typography, { variant: "body2", sx: { marginBottom: '0.5rem' } }, evento.desc),
                    React.createElement(Button, { variant: "text", onClick: function () { return setOpen(!open); }, sx: { fontSize: '0.9rem', textTransform: 'none', marginBottom: '0.5rem' } }, open ? appTranslate('moreAboutMe.hideDetails') : appTranslate('moreAboutMe.showDetails')),
                    React.createElement(Collapse, { in: open },
                        React.createElement(Typography, { variant: "body2", color: "textSecondary" }, evento.details))))));
    })));
};
export default About;
