import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import LanguageSelector from './lang/LanguageSelector';
import LoginBtn from './login-btn/loginBtn';
import { useApp } from '../../hooks/AppContext';
import { Button, Drawer, IconButton, List, ListItem, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
var NavBar = function () {
    var _a = useApp(), changeView = _a.changeView, translate = _a.translate;
    var _b = useState(false), drawerOpen = _b[0], setDrawerOpen = _b[1];
    var toggleDrawer = function () {
        setDrawerOpen(!drawerOpen);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Row, { style: {
                zIndex: 1000,
                backgroundColor: '#0a0a0a',
                padding: '10px',
                borderTop: '5px solid blue',
            }, className: "align-items-center" },
            React.createElement(Col, { xs: 3, sm: 8, md: 9, className: "d-flex justify-content-start" },
                React.createElement(IconButton, { edge: "start", color: "inherit", "aria-label": "menu", onClick: toggleDrawer, style: { backgroundColor: '#0a0a0a', color: 'white' }, sx: { display: { xs: 'block', sm: 'block', md: 'none' } } },
                    React.createElement(MenuIcon, null)),
                React.createElement(Button, { onClick: function () { return changeView(''); }, color: "inherit", sx: { textTransform: 'none', color: 'white', display: { xs: 'none', md: 'block' } } }, translate('home')),
                React.createElement(Button, { onClick: function () { return changeView('about'); }, color: "inherit", sx: { textTransform: 'none', color: 'white', display: { xs: 'none', md: 'block' } } }, translate('moreAboutMe')),
                React.createElement(Button, { onClick: function () { return changeView('news'); }, color: "inherit", sx: { textTransform: 'none', color: 'white', display: { xs: 'none', md: 'block' } } }, translate('navBar.news'))),
            React.createElement(Col, { xs: 5, sm: 2, md: 1, className: "d-flex justify-content-center" },
                React.createElement(LanguageSelector, null)),
            React.createElement(Col, { xs: 4, sm: 2, md: 2, className: "d-flex justify-content-center" },
                React.createElement(LoginBtn, null))),
        React.createElement(Drawer, { anchor: "left", open: drawerOpen, onClose: toggleDrawer, PaperProps: {
                style: {
                    backgroundColor: '#0a0a0a',
                    color: 'white',
                    borderRight: '2px solid blue'
                }
            } },
            React.createElement(List, null,
                React.createElement(ListItem, { component: "button", onClick: function () { changeView('login'); toggleDrawer(); }, style: { backgroundColor: '#0a0a0a', color: 'white' } },
                    React.createElement(ListItemText, { primary: translate('login') })),
                React.createElement(ListItem, { component: "button", onClick: function () { changeView('register'); toggleDrawer(); }, style: { backgroundColor: '#0a0a0a', color: 'white' } },
                    React.createElement(ListItemText, { primary: translate('register') })),
                React.createElement(ListItem, { component: "button", onClick: function () { changeView(''); toggleDrawer(); }, style: { backgroundColor: '#0a0a0a', color: 'white' } },
                    React.createElement(ListItemText, { primary: translate('home') })),
                React.createElement(ListItem, { component: "button", onClick: function () { changeView('about'); toggleDrawer(); }, style: { backgroundColor: '#0a0a0a', color: 'white' } },
                    React.createElement(ListItemText, { primary: translate('moreAboutMe') })),
                React.createElement(ListItem, { component: "button", onClick: function () { changeView('news'); toggleDrawer(); }, style: { backgroundColor: '#0a0a0a', color: 'white' } },
                    React.createElement(ListItemText, { primary: translate('navBar.news') }))))));
};
export default NavBar;
