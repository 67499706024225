import React from 'react';
import { useApp } from '../../../hooks/AppContext';
import { Button } from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';
var LoginBtn = function () {
    var _a = useApp(), changeView = _a.changeView, translate = _a.translate;
    return (React.createElement(Button, { variant: "text", color: "primary", startIcon: React.createElement(LoginIcon, { style: { color: 'white' } }), onClick: function () { return changeView('login'); }, sx: {
            textTransform: 'none', // Para mantener el texto en su forma original
            padding: 0, // Eliminar el padding para que se vea como texto
            minWidth: 'auto', // Eliminar el ancho mínimo del botón
            color: 'white', // Heredar el color del texto
        } }, translate('navBar.login')));
};
export default LoginBtn;
