var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { TextField, Button, Grid, InputAdornment, IconButton, Typography } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Container } from 'react-bootstrap';
import NavBar from '../../components/navBar/NavBar';
import './Register.css';
import Footer from '../../components/footer/Footer';
var LoginForm = function () {
    var _a = useState({
        email: '',
        username: '',
        password: '',
        confirmPassword: '',
        showPassword: false,
        showConfirmPassword: false,
        emailError: '',
        usernameError: '',
        passwordError: '',
        confirmPasswordError: '',
    }), formState = _a[0], setFormState = _a[1];
    var handleEmailChange = function (e) {
        var email = e.target.value;
        var emailError = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) ? '' : 'Please enter a valid email';
        setFormState(function (prevState) { return (__assign(__assign({}, prevState), { email: email, emailError: emailError })); });
    };
    var handleUsernameChange = function (e) {
        var username = e.target.value;
        var usernameError = username.length >= 4 ? '' : 'Password must be at least 4 characters';
        setFormState(function (prevState) { return (__assign(__assign({}, prevState), { username: username, usernameError: usernameError })); });
    };
    var handlePasswordChange = function (e) {
        var password = e.target.value;
        var passwordError = password.length >= 6 ? '' : 'Password must be at least 6 characters';
        setFormState(function (prevState) { return (__assign(__assign({}, prevState), { password: password, passwordError: passwordError })); });
    };
    var handleConfirmPasswordChange = function (e) {
        var confirmPassword = e.target.value;
        var confirmPasswordError = confirmPassword === formState.password ? '' : 'Confirm Password must be exactly like password';
        setFormState(function (prevState) { return (__assign(__assign({}, prevState), { confirmPassword: confirmPassword, confirmPasswordError: confirmPasswordError })); });
    };
    var handleClickShowPassword = function () {
        setFormState(function (prevState) { return (__assign(__assign({}, prevState), { showPassword: !prevState.showPassword })); });
    };
    var handleClickShowConfirmPassword = function () {
        setFormState(function (prevState) { return (__assign(__assign({}, prevState), { showPassword: !prevState.showConfirmPassword })); });
    };
    var handleSubmit = function (e) {
        e.preventDefault();
        var emailError = formState.emailError, passwordError = formState.passwordError, email = formState.email, password = formState.password;
        if (!emailError && !passwordError && email && password) {
            // Proceed with form submission (e.g., API call)
            console.log('Form submitted:', { email: email, password: password });
        }
        else {
            console.log('Form validation failed');
        }
    };
    return (React.createElement(Container, { fluid: true, className: 'register-page', style: {
            backgroundColor: '#0F52BA',
            minHeight: '100vh'
        } },
        React.createElement(NavBar, null),
        React.createElement("form", { onSubmit: handleSubmit, noValidate: true, className: 'register-form' },
            React.createElement(Grid, { container: true, direction: "column", alignItems: "center", sx: {
                    maxWidth: 450,
                    margin: '0 auto',
                    padding: '40px 20px',
                    borderRadius: 2,
                    boxShadow: 3,
                    backgroundColor: '#ADD8E6', // Fondo gris oscuro
                    border: '2px solid #ffffff', // Bordes en azul claro
                } },
                React.createElement(Typography, { variant: "h4", align: "center", gutterBottom: true, sx: { fontWeight: 'bold', color: '#191970' } }, "Register"),
                React.createElement(Grid, { item: true, xs: 12, style: { width: '100%' } },
                    React.createElement(TextField, { label: "Email", type: "email", fullWidth: true, variant: "outlined", value: formState.email, onChange: handleEmailChange, error: !!formState.emailError, helperText: formState.emailError, required: true, sx: {
                            marginBottom: 2,
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#191970', // Bordes blancos
                                },
                                '&:hover fieldset': {
                                    borderColor: '#191970', // Bordes blancos al pasar el mouse
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: formState.emailError ? '#f44336' : '#4caf50', // Bordes rojos si hay error, verde claro si está enfocado
                                },
                            },
                        }, InputLabelProps: {
                            style: { color: '#191970' }, // Etiquetas en blanco
                        }, InputProps: {
                            style: { color: '#191970' }, // Texto en blanco
                        } })),
                React.createElement(Grid, { item: true, xs: 12, style: { width: '100%' } },
                    React.createElement(TextField, { label: "Username", type: "username", fullWidth: true, variant: "outlined", value: formState.username, onChange: handleUsernameChange, error: !!formState.usernameError, helperText: formState.usernameError, required: true, sx: {
                            marginBottom: 2,
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#191970', // Bordes blancos
                                },
                                '&:hover fieldset': {
                                    borderColor: '#191970', // Bordes blancos al pasar el mouse
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: formState.usernameError ? '#f44336' : '#4caf50', // Bordes rojos si hay error, verde claro si está enfocado
                                },
                            },
                        }, InputLabelProps: {
                            style: { color: '#191970' }, // Etiquetas en blanco
                        }, InputProps: {
                            style: { color: '#191970' }, // Texto en blanco
                        } })),
                React.createElement(Grid, { item: true, xs: 12, style: { width: '100%' } },
                    React.createElement(TextField, { label: "Password", type: formState.showPassword ? 'text' : 'password', fullWidth: true, variant: "outlined", value: formState.password, onChange: handlePasswordChange, error: !!formState.passwordError, helperText: formState.passwordError, required: true, sx: {
                            marginBottom: 2,
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#191970', // Bordes blancos
                                },
                                '&:hover fieldset': {
                                    borderColor: '#191970', // Bordes blancos al pasar el mouse
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: formState.passwordError ? '#f44336' : '#4caf50', // Bordes rojos si hay error, verde claro si está enfocado
                                },
                            },
                        }, InputLabelProps: {
                            style: { color: '#191970' }, // Etiquetas en blanco
                        }, InputProps: {
                            style: { color: '#191970' }, // Texto en blanco
                            endAdornment: (React.createElement(InputAdornment, { position: "end" },
                                React.createElement(IconButton, { onClick: handleClickShowPassword }, formState.showPassword ? React.createElement(VisibilityOff, { sx: { color: '#191970' } }) : React.createElement(Visibility, { sx: { color: '#191970' } })))),
                        } })),
                React.createElement(Grid, { item: true, xs: 12, style: { width: '100%' } },
                    React.createElement(TextField, { label: "Comfirm Password", type: formState.showPassword ? 'text' : 'password', fullWidth: true, variant: "outlined", value: formState.confirmPassword, onChange: handleConfirmPasswordChange, error: !!formState.confirmPasswordError, helperText: formState.confirmPasswordError, required: true, sx: {
                            marginBottom: 2,
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#191970', // Bordes blancos
                                },
                                '&:hover fieldset': {
                                    borderColor: '#191970', // Bordes blancos al pasar el mouse
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: formState.confirmPasswordError ? '#f44336' : '#4caf50', // Bordes rojos si hay error, verde claro si está enfocado
                                },
                            },
                        }, InputLabelProps: {
                            style: { color: '#191970' }, // Etiquetas en blanco
                        }, InputProps: {
                            style: { color: '#191970' }, // Texto en blanco
                            endAdornment: (React.createElement(InputAdornment, { position: "end" },
                                React.createElement(IconButton, { onClick: handleClickShowConfirmPassword }, formState.showConfirmPassword ? React.createElement(VisibilityOff, { sx: { color: '#191970' } }) : React.createElement(Visibility, { sx: { color: '#191970' } })))),
                        } })),
                React.createElement(Grid, { item: true, xs: 12, style: { width: '100%' } },
                    React.createElement(Button, { type: "submit", variant: "contained", color: "primary", fullWidth: true, disabled: !!formState.emailError || !!formState.passwordError || !formState.email || !formState.password, sx: {
                            padding: '12px',
                            fontSize: '16px',
                            fontWeight: 'bold',
                            borderRadius: 1,
                            backgroundColor: '#00bcd4', // Botón en azul claro
                            '&:hover': {
                                backgroundColor: '#0097a7',
                            },
                        } }, "Register")))),
        React.createElement(Footer, null)));
};
export default LoginForm;
